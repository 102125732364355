import React from 'react';
import { graphql } from 'gatsby';
import { Global } from '@emotion/core';
import { Container, Row, Col } from 'emotion-flex';
import Video from '../components/Video';
import { withLayout } from '../components/Layout';
import { SEO } from '../components';
import presets from '../styles/presets';
import theme from '../styles/theme';
import { ContentfulVideoPost } from '../graphql-types';

interface Props {
  pageContext: {
    locale: string;
  };
  data: {
    video: ContentfulVideoPost;
  };
}

const VideoDetailPageTemplate: React.FC<Props> = ({
  pageContext: { locale },
  data: { video },
}) => {
  return (
    <>
      <SEO
        lang={locale}
        title={video.seoTitle}
        description={video.seoDescription}
      />
      <Global
        styles={{
          blockQuote: {
            backgroundColor: theme.colors.lightBg,
            margin: 0,
            padding: '1.75rem',
            marginBottom: '1rem',
          },
        }}
      />
      <div
        css={{
          marginBottom: 48,
          [presets.sm]: {
            margin: '26px 0 48px 0',
          },
        }}
      >
        <div css={{ maxWidth: 730, marginLeft: 'auto', marginRight: 'auto' }}>
          <Video videoSrcURL={video.youTubeEmbedUrl} />
        </div>
        <Container>
          <Row>
            <Col mdOffset={2} md={8}>
              <div
                dangerouslySetInnerHTML={{
                  __html: video.content.childMarkdownRemark.html,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export const videoDetailPageQuery = graphql`
  query videoPostByID($id: String!) {
    video: contentfulVideoPost(id: { eq: $id }) {
      id
      slug
      seoTitle
      seoDescription
      youTubeEmbedUrl
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

export default withLayout(VideoDetailPageTemplate, { isNewLayout: true });
